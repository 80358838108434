<template>
	<div class="DeviceEventCfgList">
		
		<el-row >
			<el-button type="primary"  size="mini" @click="addEvent()">新增</el-button>
			<el-button v-if="selMode" type="primary"  size="mini" @click="closeEventCfgList()">关闭</el-button>
			<el-button type="primary"  size="mini" @click="refresh()">刷新</el-button>
		</el-row>
		
		<table v-if="plist && plist.length > 0" class="configItemTalbe" width="99%">
			<thead>
				<tr>
					<td v-if="!selMode">{{"ID"|i18n}}</td>
					<td>{{'产品名称'}}</td>
					<td>{{'设备名称'}}</td>
					<td>{{'事件归属'}}</td>
					<td>{{'事件类型'}}</td>
					<td v-if="!selMode">{{'cmdId'|i18n}}</td>
					<td v-if="!selMode">{{'clientId'|i18n}}</td>
					<td v-if="!selMode">{{'createdBy'|i18n}}</td>
					<td>{{'status'|i18n}}</td>
					<td>{{'desc'|i18n}}</td>
					<td>{{'sext1'|i18n}}</td>
					<td>{{'sext2'|i18n}}</td>
					<td>{{"Operation"|i18n}}</td>
				</tr>
			</thead>
			<tr v-for="c in plist" :key="'h_'+c.id">
				<td v-if="!selMode">{{c.id}}</td>
				<td>{{prdMap[c.productId]}}</td>
				<td >{{myDeviceMap[c.srcDeviceId]}}</td>
				<td>{{$jm.IOT.JM_OP_SRC_LABELS[c.by-1]}}</td>
				<td>{{$jm.IOT.getEventCodeLabel(c.eventCode)}}</td>
				<td v-if="!selMode">{{c.cmdId}}</td>
				<td v-if="!selMode">{{c.clientId}}</td>
				<td v-if="!selMode">{{c.createdBy}}</td>
				<td>{{c.status==1?"启用":"禁用"}}</td>
				<td class="descCol">{{c.desc}}</td>
				<td>{{c.sext1}}</td>
				<td>{{c.sext2}}</td>
				<td>
					<a v-if="selMode"  @click="selectEvent(c)">{{'Select'|i18n}}&nbsp;</a>
					<a @click="viewDetail(c)">{{'View'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="updateCmd(c)">{{'Update'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="deleteAs(c)">{{'Delete'|i18n}}&nbsp;</a>
				</td>
			</tr>
		</table>

		<div v-if="isLogin && plist && plist.length > 0 && !fromOp" style="position:relative;text-align:center;">
			<Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
				show-elevator show-sizer show-total @on-change="curPageChange" @on-page-size-change="pageSizeChange"
				:page-size-opts="[10, 30, 60,100]"></Page>
		</div>

		<div v-if="!isLogin">
			No permission!
		</div>

		<div v-if="!plist || plist.length == 0">
			No data!
		</div>
		
		<Drawer ref="asInfo"  v-model="asDrawer.drawerStatus" :closable="false" placement="right" 
			:transfer="false"
			:styles="asDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" 
			width="50" :mask-closable="false" :mask="false" :z-index="99999"
			style="z-index: 33333;">
			
			<el-row>
				<el-button size="mini" @click="asDrawer.drawerStatus = false">关闭</el-button>
				<el-button  :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">确定</el-button>
			</el-row>
			
			<el-row>
				<el-col>事件归属类型</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.by" :disabled="model==3">
						<el-option v-for="(v,k) in $jm.IOT.JM_OP_SRC" :key="v" :value="v" 
						:label="$jm.IOT.JM_OP_SRC_LABELS[v-1]"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<!-- 在主设备上增加从设备指令，这里选择从设备 -->
			<el-row v-if="as.by==$jm.IOT.JM_OP_SRC.DEVICE">
				<el-col>事件设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.srcDeviceId" 
						:disabled="model==3 || by==$jm.IOT.JM_OP_SRC.DEVICE">
						<el-option v-for="(v,k) in myDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row v-if="as.by==$jm.IOT.JM_OP_SRC.PRODUCT">
				<el-col>事件所属产品</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.productId" :disabled="model==3">
						<el-option v-for="(val,key) in prdMap" :key="key" :value="key" :label="val"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>触发事件类型</el-col>
				<el-col>
					<!--
					<el-select style="width:100%" v-model="as.eventCode" :disabled="model==3">
						<el-option v-for="(v,k) in $jm.IOT.EVENT_CODES" :key="v" :value="v" 
						:label="$jm.IOT.JM_EVENT_CODE_LABELS[v-1]"></el-option>
					</el-select>
					-->
					<el-select style="width:100%" v-model="as.eventCode" :disabled="model==3">
						<el-option v-for="(v,k) in eventCodeTypes" :key="v.key" :value="v.attr0" 
						:label="v.name"></el-option>
					</el-select>
					
				</el-col>
			</el-row>
			
			 <el-row>
				<el-col>描述</el-col>
				<el-col><el-input v-model="as.desc" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="as.status" :disabled="model==3" placeholder="请选择">
					<el-option :key="1" value="1" label="启用"></el-option>
					<el-option :key="0" value="0" label="禁用"></el-option>
				</el-select>
			 </el-row>
			<el-row>
				<el-col>{{cmdNameLabel}}</el-col>
				<el-col><el-input v-model="as.cmdId"
				:disabled="model==3 || as.eventCode==$jm.IOT.EVENT_CODES.ASR"/></el-col>
			</el-row>
			
			<el-row v-if="sext1Label">
				<el-col>{{sext1Label}}</el-col>
				<el-col><el-input v-model="as.sext1" :disabled="model==3" /></el-col>
			</el-row>
			
			 <el-row v-if="sext2Label">
				<el-col >{{sext2Label}}</el-col>
				<el-col><el-input v-model="as.sext2" 
				:disabled="model==3 || as.eventCode==$jm.IOT.EVENT_CODES.ASR" /></el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>字符串扩展字段Ext3</el-col>
				<el-col><el-input v-model="as.sext3" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>整数扩展字段Ext1</el-col>
				<el-col><el-input v-model="as.iext1" :disabled="model==3" /></el-col>
			 </el-row>
			 <el-row>
				<el-col>整数扩展字段Ext2</el-col>
				<el-col><el-input v-model="as.iext2" :disabled="model==3" /></el-col>
			 </el-row>
			 <el-row>
				<el-col>整数扩展字段Ext3</el-col>
				<el-col><el-input v-model="as.iext3" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <!--
			 <el-row>
			 	<el-col>{{"ClientId"|i18n}}</el-col>
			 	<el-col>
			 		<el-select style="width:100%" v-model="as.clientId" 
			 					:disabled="model==3 || by==$jm.IOT.JM_OP_SRC.DEVICE" placeholder="请选择">
			 			<el-option v-for="o in $jr.auth.getClients()" :key="o" :value="o" :label="o"></el-option>
			 		</el-select>
			 	</el-col>
			  </el-row>
			  -->
		</Drawer>
		
	</div>
</template>

<script>
	
	import jmiot from "../../rpcservice/jm.js"
	
	const cid = 'DeviceEventCfgList';

	export default {
		name: cid,
		components: {},
		props: {
			selMode: {
				type: Boolean,
				default: false,
			},
		},
		
		data() {
			return {
				as: {},
				model: 3,

				errorMsg: '',
				isLogin: true,
				plist: [],

				cmdNameLabel:"语音命令",
				sext1Label:null,
				sext2Label:null,
				myDeviceMap: {},
				
				funDef: {},
				cmd: {},
				fromOp: false,
				dev:{},
				by:jmiot.IOT.JM_OP_SRC.DEVICE,
				
				prdMap:{},
				eventCodeTypes:[],

				queryParams: {
					size: 30,
					curPage: 1,
					ps: {
						selectType: 0
					}
				},
				totalNum: 0,

				asDrawer: {
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px'
					},
				},
			}
		},

		watch:{
            "as.eventCode":function(eventCode){
			   if(eventCode == this.$jm.IOT.EVENT_CODES.ASR) {
				    this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"ID（提交后由后台自动分配）"
					this.sext1Label = "语音命令"
					this.sext2Label = "拼音命令"
			   } else if(eventCode == this.$jm.IOT.EVENT_CODES.IR){
					this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"键码值"
				   this.sext1Label = null
				   this.sext2Label = null
			   } else if(eventCode == this.$jm.IOT.EVENT_CODES.KEY){
				   this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"键码"
				   this.sext1Label = null
				   this.sext2Label = null
			   } else {
				   this.cmdNameLabel = "命令ID"
				   this.sext1Label = "字符串扩展字段1"
				   this.sext2Label = "字符串扩展字段2"
			   }
            }
        },
		
		methods: {
			
			selectEvent(c){
				if(this.selMode) {
					console.log("selectEvent",c)
					 this.$emit('onSeletEvent', c)
				}
			},
			
			closeEventCfgList(){
				if(this.selMode) {
					 this.$emit('onClose')
				}
			},
			
			async loadMasterDevice(){
				
				let loaded = false;
				for(let k in this.myDeviceMap) {
					loaded = true;
					break;
				}
				
				if(loaded) {
					return
				}
				
				let r = await this.$jr.rpc.invokeByCode(-2138649681, [this.$jm.IOT.DEV_ROLE.ALL])
				console.log("Master Data", JSON.stringify(r))
				if(r.code != 0) {
					this.$notify.error({title: '提示',message: r.msg || '加载主设备数据错误'})
					return
				}
				
				if(!r.data || r.data.length == 0) {
					map["0"] = "" //防止重复加载无效请求
					console.log("无主设备数据")
					return
				}
				this.myDeviceMap = r.data
			},
			
			closeDrawer() {
				this.$parent.$parent.closeAsDrawer()
			},

			resetData() {
				this.as = {eventCode: this.$jm.IOT.EVENT_CODES.ASR,
					status:true, by: this.by}
			},

			viewDetail(as) {
				this.model = 3
				this.as = as
				this.as.eventCode +="" 
				this.as.productId += ""
				this.as.status+=""
				//this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			async addEvent(def, cmd, by, dev) {
				this.funDef = def
				this.cmd = cmd
				this.by = by
				this.dev = dev
				
				if(cmd) {
					this.fromOp = true
				}
				
				//console.log("by="+by);
				//console.log("dev",this.dev);
				//console.log("fromOp="+this.fromOp);
				
				this.model = 2
				this.resetData()
				if(this.fromOp) {
					this.as.opId = this.cmd.id
					if(this.by == this.$jm.IOT.JM_OP_SRC.DEVICE) {
						this.as.srcDeviceId = this.cmd.deviceId
						this.as.clientId = this.dev.srcClientId
						this.myDeviceMap[this.cmd.deviceId] = this.dev.name
					} else {
						this.loadMasterDevice()	
					}
				} else {
					await this.loadMasterDevice()
				}
				
				this.asDrawer.drawerBtnStyle.zindex = 99999
				this.asDrawer.drawerStatus = true
			},

			updateCmd(o) {
				this.model = 1;
				this.as = o
				this.as.productId += ""
				this.as.eventCode +="" 
				this.as.status+=""
				//this.resetData()
				//this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			async deleteAs(c) {
				
				this.$confirm('此操作将永久删除当前数据，是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(async () => {
					let r = await this.$jr.rpc.invokeByCode(-1067459898, [c.id])
					if (r.code == 0) {
						let idx = this.plist.findIndex(e => e.id == c.id)
						if (idx >= 0) {
							this.plist.splice(idx, 1)
						}
						this.$notify.info({
							title: '提示',
							message: "删除成功"
						})
					} else {
						this.$notify.error({
							title: '提示',
							message: r.msg || "删除失败"
						})
					}
				}).catch(() => {
				});
			},

			doQuery() {
				this.queryParams.curPage = 1
				this.refresh();
			},

			doAddOrUpdateParam() {
				
				if(!(this.as.by == this.$jm.IOT.JM_OP_SRC.DEVICE ||
					this.as.by == this.$jm.IOT.JM_OP_SRC.PRODUCT) ) {
					this.$notify.error({
						title: '提示',
						message: "事件归属无效"
					});
					return;
				}

				if(this.as.by == this.$jm.IOT.JM_OP_SRC.DEVICE && !this.as.srcDeviceId) {
					this.$notify.error({
						title: '提示',
						message: "需选择一个触发此事件的设备"
					});
					return;
				}
				
				if(this.as.by == this.$jm.IOT.JM_OP_SRC.PRODUCT && !this.as.productId) {
					this.$notify.error({
						title: '提示',
						message: "产品类型无效"
					});
					return;
				}

				if (!this.as.eventCode) {
					this.$notify.error({
						title: '提示',
						message: "事件类型不能为空"
					});
					return;
				}

				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.IR) {
					if (this.as.cmdId<=0) {
						this.$notify.error({
							title: '提示',
							message: "事件类型不能为空"
						});
						return;
					}
				}
				
				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.KEY) {
					if (this.as.cmdId<=0) {
						this.$notify.error({
							title: '提示',
							message: "按键码不能为空"
						});
						return;
					}
				}
				
				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.ASR 
					&& !this.as.sext1) {
					this.$notify.error({
						title: '提示',
						message: "语音命令词为空"
					});
					suc.success(r);
				}

				console.log(this.as);

				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(1053300880, [this.as])
					.then((resp) => {
						this.refresh();
						if (resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "更新成功"
							});
							this.asDrawer.drawerStatus = false;
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							});
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						});
					});
				} else if (this.model == 2) {
					//add
					this.$jr.rpc.invokeByCode(-371384652, [this.as])
					.then((resp) => {
						if (resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "保存成功"
							});
							this.asDrawer.drawerStatus = false;
							//this.refresh()
							this.plist.push(resp.data)
							if(this.fromOp) {
								 this.$emit('onAddSuccess', resp.data)
							}
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							});
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						});
					});
				}

			},

			async getPrdMap() {
				//产品的KEYVAlue集合
				let r = await this.$jr.rpc.invokeByCode(-414142670, [])
				console.log("getPrdMap result: ",r)
				if(r.code != 0) {
					 this.$notify.error({title: '提示',message: r.msg});
					return
				}
				let pm = {}
				for(let k in r.data) {
					pm[k+''] = r.data[k]
				}
				this.prdMap = pm
				console.log(this.prdMap)
			},
			
			curPageChange(curPage) {
				this.queryParams.curPage = curPage
				this.refresh();
			},

			pageSizeChange(pageSize) {
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
				this.refresh();
			},
			
			loadEventListByOpId() {
				this.$jr.rpc.invokeByCode(332762450, [this.cmd.id])
				.then((resp) => {
					if (resp.code == 0) {
						console.log(resp)
						this.plist = resp.data
						this.totalNum = resp.total
					} else {
						this.plist = []
						this.totalNum = 0
						this.$notify.error({ title: '提示',message: r.msg })
					}
				}).catch((err) => {
					window.console.log(err)
				});
			},

			async refresh() {
				let self = this;

				this.isLogin = this.$jr.auth.isLogin();
				if (this.isLogin) {
					
					console.log("refresh");
					await this.loadMasterDevice();
					
					if(this.fromOp) {
						this.loadEventListByOpId()
						return
					}
					
					let params = this.getQueryConditions();
					let self = this;

					//listActiveSources
					this.$jr.rpc.invokeByCode(415654022, [params])
						.then((resp) => {
							if (resp.code == 0) {
								if (resp.total == 0) {
									this.plist = [];
									this.totalNum = 0;
									this.$notify.info({
										title: '提示',
										message: "查无数据"
									});
								} else {
									console.log(resp)
									this.plist = resp.data;
									this.totalNum = resp.total;
								}
							} else {
								this.plist = [];
								this.totalNum = 0;
								this.$notify.error({
									title: '提示',
									message: r.msg
								});
							}
						}).catch((err) => {
							window.console.log(err);
						});
				} else {
					self.roleList = [];
				}
			},

			getQueryConditions() {
				//this.queryParams.ps.masterDeviceId = this.dev.deviceId
				if(this.fromOp) {
					this.queryParams.ps.opId = this.cmd.id
				}
				return this.queryParams;
			}
			
	},

	async mounted() {
			//this.$el.style.minHeight = (document.body.clientHeight - 67) + 'px';
			this.$jr.auth.addActListener(this.refresh);
			//this.refresh();
			await this.getPrdMap();
			
			let r = await this.$jr.api.getDataType("iot_eventCode")
			if(r.code == 0) {
				this.eventCodeTypes = r.data
			}else {
				console.log(r)
			}
				
			let self = this;
			this.$bus.$emit("editorOpen", {
				"editorId": cid,
				"menus": [{
						name: "REFRESH",
						label: "Refresh Event",
						icon: "ios-cog",
						call: self.refresh
					},
					{
						name: "Add",
						label: "Add Event",
						icon: "ios-cog",
						call: self.addEvent
					},
				]
			});

			let ec = function() {
				this.$jr.auth.removeActListener(cid);
				this.$off('editorClosed', ec);
			}

			this.$bus.$on('editorClosed', ec);
		},

		beforeDestroy() {
			this.$jr.auth.removeActListener(cid);
		},

	}
</script>

<style>
	.DeviceEventCfgList {
		border-top: 1px dotted lightgray;
		margin-top: 6px;
		padding-top: 10px;
		text-align: left;
	}

	.title {
		font-weight: bold;
		font-size: 17px;
	}

	.valCol {
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
</style>